import React from "react";
import { Container, Row, Col} from "react-bootstrap";
import {Link} from 'gatsby'
import { Section} from "../../components/Core";
import {StaticImage} from 'gatsby-plugin-image';


const BlogList = () => (
  <>
    {/* <!-- Blog section --> */}
    <Section className="position-relative" id='bloglistmarginleft'>
    <Container >
      <h2 style={{fontSize: 45, textAlign: 'center'}}  id='indexTitle'>Banque Académie</h2>
      <h3 style={{fontSize: 20, fontWeight: 300, textAlign: 'center'}} id='indexTitle'>Découvrez tous nos guides et fiches pratiques</h3>
    <Row className="align-items-center justify-content-center" id='bloglistwidth' style={{marginTop: 50}}>

    <Col xl='3' id='blogCol'>
      <Link to={'/academie/changer-de-banque-comment-faire/'} id='textOnHover'><div style={{border: '1px solid rgba(0,0,0,.125)'}}id='cardOnHover'>
      <StaticImage src='../../images/changer-de-banque.jpeg' width={450} height={300} alt='how to start a business' placeholder='tracedSVG' style={{marginLeft: -1}}/>
      <div style={{padding: 15}}><h3>
      Changer De Banque – Comment Faire ?
        </h3><p>
        Le changement de banque, ou mobilité bancaire, implique d’opérer la domiciliation bancaire au sein d’un nouvel ét ...
      </p></div></div></Link></Col>

      <Col xl='3' id='blogCol'>
      <Link to={'/academie/ouvrir-un-compte-bancaire-en-ligne/'} id='textOnHover'><div style={{border: '1px solid rgba(0,0,0,.125)'}}id='cardOnHover'>
      <StaticImage src='../../images/ouvrir-un-compte-en-banque.jpeg' width={450} height={300} alt='get more sales' placeholder='tracedSVG' style={{marginLeft: -1}}/>
      <div style={{padding: 15}}><h3>
      Ouvrir Un Compte Bancaire En Ligne
        </h3><p>
        L’ouverture d’un compte bancaire est indispensable, ne serait-ce que pour recevoir de l’argent ou effectuer des pai ...
      </p></div></div></Link></Col>

      <Col xl='3' id='blogCol'>
      <Link to={'/academie/cloturer-son-compte-bancaire-comment-faire/'} id='textOnHover'><div style={{border: '1px solid rgba(0,0,0,.125)'}}id='cardOnHover'>
      <StaticImage src='../../images/cloturer-un-compte-bancaire.jpeg' width={450} height={300} alt='make a website' placeholder='tracedSVG' style={{marginLeft: -1}}/>
      <div style={{padding: 15}}><h3>
      Clôturer Son Compte Bancaire – Guide
        </h3><p>
        Aujourd’hui, clôturer un compte bancaire est devenu plus simple grâce à l’intervention des pouvoirs publics et les m ...
      </p></div></div></Link></Col>
      <Col xl='3' id='blogCol'>
      <Link to={'/academie/comparateur-de-banque-en-ligne-trouver-la-moins-chere/'} id='textOnHover'><div style={{border: '1px solid rgba(0,0,0,.125)'}}id='cardOnHover'>
      <StaticImage src='../../images/trouver-la-moins-chere.jpeg' width={450} height={300} alt='learn to code' placeholder='tracedSVG' style={{marginLeft: 0}}/>
      <div style={{padding: 15}}><h3>
      Comparateur De Banque En Ligne
        </h3><p>
        Avec un univers bancaire qui comporte de nombreuses lignes de tarification et une concurrence qui s’accroît avec ...
      </p></div></div></Link></Col>

    <Col xl='3' id='blogCol'>
      <Link to={'/academie/les-neo-banques-cest-quoi/'} id='textOnHover'><div style={{border: '1px solid rgba(0,0,0,.125)'}}id='cardOnHover'>
      <StaticImage src='../../images/les-neobanques-cest-quoi.jpeg' width={450} height={300} alt='learn to code' placeholder='tracedSVG' style={{marginLeft: 0}}/>
      <div style={{padding: 15}}><h3>
      Les Néo-banques – C’est Quoi ?
        </h3><p>
        Apparues dès 2009 aux Etats-Unis, les néo-banques, aussi appelées banques digitales ou 100 % mobile, ont pour ...
      </p></div></div></Link></Col>


      <Col xl='3' id='blogCol'>
      <Link to={'/academie/frais-de-decouvert-des-banques-quelle-est-la-moins-chere/'} id='textOnHover'><div style={{border: '1px solid rgba(0,0,0,.125)'}}id='cardOnHover'>
      <StaticImage src='../../images/frais-de-decouvert.jpeg' width={450} height={300} alt='learn to code' placeholder='tracedSVG' style={{marginLeft: 0}}/>
      <div style={{padding: 15}}><h3>
      Frais De Découvert Des Banques
        </h3><p>
        Sujet délicat, les frais de découvert ne sont pas toujours évidents dans les grilles tarifaires des établissements trad ...
      </p></div></div></Link></Col>

      <Col xl='3' id='blogCol'>
      <Link to={'/academie/cotisation-carte-bancaire-quelle-est-la-banque-la-moins-chere/'} id='textOnHover'><div style={{border: '1px solid rgba(0,0,0,.125)'}}id='cardOnHover'>
      <StaticImage src='../../images/cotisation-carte-bancaire.png' width={450} height={300} alt='learn to code' placeholder='tracedSVG' style={{marginLeft: 0}}/>
      <div style={{padding: 15}}><h3>
      Cotisation Des Cartes Bancaires
        </h3><p>
        C’est l’une des principales dépenses en matière bancaire lorsque l’on est client chez une banque traditionnelle, la ...
      </p></div></div></Link></Col>

      <Col xl='3' id='blogCol'>
      <Link to={'/academie/erreurs-a-eviter-en-banque-en-ligne/'} id='textOnHover'><div style={{border: '1px solid rgba(0,0,0,.125)'}}id='cardOnHover'>
      <StaticImage src='../../images/erreurs-a-eviter-banque-en-ligne.jpeg' width={450} height={300} alt='new twitter account' placeholder='tracedSVG' style={{marginLeft: -1}}/>
      <div style={{padding: 15}}><h3>
      Erreurs À Éviter Avec Une Banque En Ligne
        </h3><p>
        Tarifs attractifs, offre de bienvenue, conseillers disponibles, simplicité, …. Faut-il se laisser séduire par les ban ...
      </p></div></div></Link></Col>
      
     

      <Link to='/academie/' style={{color: 'black', fontSize: 21}}>Tous les articles de la banque académie</Link>
     


    </Row>
    </Container>
      



    </Section>
  </>
);

export default BlogList;
