import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import {Link} from 'gatsby'
import * as Bs from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight} from '@fortawesome/free-solid-svg-icons'
// import startupmag from "../../assets/image/png/meilleure-banque.png";
import {StaticImage} from 'gatsby-plugin-image';

import {
  Title,
  Section,
  Box,
  Text,
} from "../../components/Core";

import { device } from "../../utils";

const SectionStyled = styled(Section)`
  

  background: rgb(240 0 87);
  background-size: cover;
  background-position: bottom left;

  @media ${device.lg} {
    background-position: center;
  }
`;




const Hero = () => {

  
  return (
    <>
      {/* <!-- Hero Area --> */}
      <SectionStyled>
        <div className="pt-5"></div>
        <Container id='heroMargin'>
          <Row>
            
            <Col className="order-2" id='heroImage' style={{marginLeft: -17}}>
              <Link to={'/comparateur/'}>
    
            
            <StaticImage
		  	src='../../images/meilleure-banque-bankbank.png' width={1200}
			alt='meilleure banque bankbank'
			placeholder='tracedSVG'
			/>
            
            </Link>
            </Col>

            
            <Col
              md="9"
              lg="7"
              className="order-1"
              style={{marginLeft: -30}}
            >
              <Box py={[null, null, null, 5]} pr={5} pt={[4, null]} id='heroBox'>
                <Title variant="hero" style={{marginTop: -70, marginBottom: 23, color: 'white'}} id='heroTitle'>
                  Trouvez La<br></br>Meilleure Banque
                </Title>
                <Text mb={4}id='heroParaf' style={{color: 'white'}}>
                Comparez les banques par offres promos, cartes bancaires et conditions de revenus. Plus aucune seconde à attendre.
          
                    
                </Text>
                <Link to={'/comparateur/'}>
                <Bs.Button id="comparateurButton" type="submit" className="btn-lg" style={{height: 60, backgroundColor: '#ffc107', borderColor: '#ffc107', color: '#29313d'}}>C'est parti! <FontAwesomeIcon icon={faAngleDoubleRight} style={{marginTop: 5, marginBottom: -2, fontSize: 24, marginLeft: 10}}/></Bs.Button>
                </Link>
               


              </Box>
            </Col>
          
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default Hero;
