import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {Link } from 'gatsby'
import { Title, Section, Box, Text } from "../../components/Core";
import { device } from "../../utils";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCreditCard, faLaptop, faMobileAlt, faMoneyCheck, faBuilding, faCity} from '@fortawesome/free-solid-svg-icons'




const Content2 = () => (
  <>
    {/* <!-- Content section --> */}
    <Section className="position-relative" id='compareboxmarginleft'>
      <Container id='indexBlogList'>
        {/* <h2 style={{textAlign: 'center', marginBottom: 50, fontSize: 50, fontWeight: 800, color: '#1c2d35'}}>Nos comparatifs</h2> */}
        <Row className="align-items-center" id='rowlefty'>
       
       <Col> 
   


  <Link to={'/banque-en-ligne/'} id='textOnHover'> <Box width={360} id='iconBox' bg="light" border="1px solid" borderColor="border" p="20px" style={{marginBottom: 20}} borderRadius={10} className={`d-flex`} css={`min-width: 100%;width: 100%;@media ${device.sm}`}>
    <Box size={55} minWidth={55} borderRadius="50%" color={'red'} fontSize="28px" className="d-flex justify-content-center align-items-center"
    style={{backgroundColor: 'rgba(240,64,55,0.1)'}} mr={3} >
      <FontAwesomeIcon icon={faLaptop} style={{color: '#e4234f'}}/>
    </Box>
    <div><Title variant="card" mb={0}>
    Banques en ligne
      </Title>
      <Text><ul style={{marginTop: 10, listStyleType: 'square'}}>
        <li style={{fontSize: 18}}>offres promos</li>
        <li style={{fontSize: 18}}>frais cartes</li>
        <li style={{fontSize: 18}}>conditions d'ouverture</li>
        </ul></Text>
    </div>
  </Box></Link>

  <Link to={'/carte-bancaire/'} id='textOnHover'> <Box width={360} id='iconBox' bg="light" border="1px solid" borderColor="border" p="20px" style={{marginBottom: 20}} borderRadius={10} className={`d-flex`} css={`min-width: 100%;width: 100%;@media ${device.sm}`}>
    <Box size={55} minWidth={55} borderRadius="50%" color={'red'} fontSize="28px" className="d-flex justify-content-center align-items-center"
    style={{backgroundColor: 'rgba(65, 62, 101, 0.1)'}} mr={3} >
      <FontAwesomeIcon icon={faCreditCard}  style={{color: 'rgb(33, 37, 41)'}}/>
    </Box>
    <div><Title variant="card" mb={0}>
    Cartes Bancaires
      </Title>
      <Text><ul style={{marginTop: 10, listStyleType: 'square'}}>
        <li style={{fontSize: 18}}>cartes gratuites</li>
        <li style={{fontSize: 18}}>premier / gold</li>
        <li style={{fontSize: 18}}>haut de gamme</li>
        </ul></Text>
    </div>
  </Box></Link>


                  </Col>
       <Col> 
       
       


  <Link to={'/neobanque/'} id='textOnHover'> <Box width={360} id='iconBox' bg="light" border="1px solid" borderColor="border" p="20px" style={{marginBottom: 20}} borderRadius={10} className={`d-flex`} css={`min-width: 100%;width: 100%;@media ${device.sm}`}>
    <Box size={55} minWidth={55} borderRadius="50%" color={'red'} fontSize="28px" className="d-flex justify-content-center align-items-center"
    style={{backgroundColor: 'rgb(253 211 49 / 19%)'}} mr={3} >
      <FontAwesomeIcon icon={faMobileAlt}  style={{color: 'rgb(254, 220, 90)'}}/>
    </Box>
    <div><Title variant="card" mb={0}>
    Néobanques
      </Title>
      <Text><ul style={{marginTop: 10, listStyleType: 'square'}}>
        <li style={{fontSize: 18}}>comparez les tarifs</li>
        <li style={{fontSize: 18}}>taux de transferts</li>
        <li style={{fontSize: 18}}>frais de retraits</li>
        </ul></Text>
    </div>
  </Box></Link>

  <Link to={'/banque-pro/'} id='textOnHover'> <Box width={360} id='iconBox' bg="light" border="1px solid" borderColor="border" p="20px" style={{marginBottom: 20}} borderRadius={10} className={`d-flex`} css={`min-width: 100%;width: 100%;@media ${device.sm}`}>
    <Box size={55} minWidth={55} borderRadius="50%" color={'red'} fontSize="28px" className="d-flex justify-content-center align-items-center"
    style={{backgroundColor: 'rgba(65, 62, 101, 0.1)'}} mr={3} >
      <FontAwesomeIcon icon={faBuilding}  style={{color: 'rgb(33, 37, 41)'}}/>
    </Box>
    <div><Title variant="card" mb={0}>
    Banques Pros
      </Title>
      <Text><ul style={{marginTop: 10, listStyleType: 'square'}}>
        <li style={{fontSize: 18}}>auto-entrepreneurs</li>
        <li style={{fontSize: 18}}>EURL, SARL, SAS, SA</li>
        <li style={{fontSize: 18}}>associations</li>
        </ul></Text>
    </div>
  </Box></Link>


     
                  </Col>
       <Col>
       


  <Link to={'/compte-courant/'} id='textOnHover'> <Box width={360} id='iconBox' bg="light" border="1px solid" borderColor="border" p="20px" style={{marginBottom: 20}} borderRadius={10} className={`d-flex`} css={`min-width: 100%;width: 100%;@media ${device.sm}`}>
    <Box size={55} minWidth={55} borderRadius="50%" color={'red'} fontSize="28px" className="d-flex justify-content-center align-items-center"
    style={{backgroundColor: 'rgb(242 248 237)'}} mr={3} >
      <FontAwesomeIcon icon={faMoneyCheck}  style={{color: 'rgb(119 191 65)'}}/>
    </Box>
    <div><Title variant="card" mb={0}>
    Comptes Courants
      </Title>
      <Text><ul style={{marginTop: 10, listStyleType: 'square'}}>
        <li style={{fontSize: 18}}>prix (€)/mois</li>
        <li style={{fontSize: 18}}>conditions de revenus</li>
        <li style={{fontSize: 18}}>tenue de compte</li>
        </ul></Text>
    </div>
  </Box></Link>

  <Link to={'/banque/'} id='textOnHover'> <Box width={360} id='iconBox' bg="light" border="1px solid" borderColor="border" p="20px" style={{marginBottom: 20}} borderRadius={10} className={`d-flex`} css={`min-width: 100%;width: 100%;@media ${device.sm}`}>
    <Box size={55} minWidth={55} borderRadius="50%" color={'red'} fontSize="28px" className="d-flex justify-content-center align-items-center"
    style={{backgroundColor: 'rgba(240,64,55,0.1)'}} mr={3} >
      <FontAwesomeIcon icon={faCity}  style={{color: '#e4234f'}}/>
    </Box>
    <div><Title variant="card" mb={0}>
    Toutes Les Banques
      </Title>
      <Text><ul style={{marginTop: 10, listStyleType: 'square'}}>
        <li style={{fontSize: 18}}>fiches banques</li>
        <li style={{fontSize: 18}}>tarifs</li>
        <li style={{fontSize: 18}}>offres promos</li>
        </ul></Text>
    </div>
  </Box></Link>



    
                  </Col>
               
          
        
        </Row>
      </Container>
    </Section>
  </>
);

export default Content2;
